import { useQuery, useQueryClient } from "@tanstack/vue-query";

const fetchLabelTranslations = async () => {
  console.log("Fetch translations!");
  const res = await fetchWithAuth("/api/labelTranslations");

  console.log(res);

  return res;
};

export const useLabelTranslationsQuery = () => {
  return useQuery({
    queryKey: ["labelTranslations"],
    queryFn: fetchLabelTranslations,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: "Infinity",
  });
};

export const useLabelTranslationsPrefetch = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.prefetchQuery({
      queryKey: ["labelTranslations"],
      queryFn: fetchLabelTranslations,
      // staleTime: 600000, // 10 minutes
    });
};
